<template>
    <div>
        <h1 class="mb-6">{{ article.title }}</h1>
        <div class="bg-gray-200 w-full flex justify-center mb-6 article-image-height">
            <img :src="article.media" alt="" class="object-contain" style="height: 100%; width: auto">
        </div>
        <h2 class="py-4">
            <b>{{ article.content_summary }}</b>
        </h2>
        <span v-html="article.content"></span>
    </div>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true,
        }
    }
}
</script>
