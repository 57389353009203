<template>
    <div>
        <h1 class="mb-3 md:mb-8 mx-8 md:mx-0">
            {{ article.title }}
        </h1>
        <div class="bg-gray-200 w-full flex justify-center mb-6">
            <img :src="article.media" alt="" class="object-contain" style="height: auto; width: 100%">
        </div>
        <div class="bg-gray-50 shadow-md">
            <h3 class="py-4 px-8">
                <b>{{ article.content_summary }}</b>
            </h3>
            <div class="border-b border-main mx-8"></div>
            <div class="px-8 pb-3">
                <span v-html="article.content"></span>
            </div>
            <div class="flex flex-col items-center py-8">
                <div class="text-sm">Publisert: {{ article.created_at }}</div>
                <div class="text-sm">Oppdatert: {{ article.updated_at }}</div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true,
        }
    }
}
</script>
