<template>
    <div class="w-full sm:w-1/2 xl:w-1/3 px-4 mb-16">
        <a :href="`/articles/${article.id}`">
            <div class="bg-gray-200 flex justify-center" style="height: 35vh">
                <img v-if="article.media" :src="article.media" alt="" class="object-cover" style="height: 100%; width: 100%">
            </div>
        </a>
        <div class="flex flex-col justify-between p-3 bg-gray-50 rounded shadow-md" style="height: 220px">
            <div>
                <h2 class="text-lg md:text-xl mt-0">
                    {{ article.title }}
                </h2>
                <p>
                    {{ article.content_summary }}
                </p>
            </div>

            <div class="flex justify-between items-center">
                <a :href="`/articles/${article.id}`" class="text-main hover:text-white">
                    Les mer
                </a>
                <div class="text-sm">
                    Oppdatert: {{ article.updated_at }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true,
        }
    }
}
</script>
