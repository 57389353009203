<template>
    <div class="px-6 md:px-24 flex items-center justify-between sticky top-0 bg-secondary z-50 border-b border-gray-300" style="height: 60px">
        <!-- Desktop -->
        <div class="flex items-center">
            <a href="/" >
                <img src="/images/logo.png" alt="Berget Applications - Logo" class="h-8 md:h-10">
            </a>
            <div class="hidden md:flex">
                <a href="/" class="ml-8 px-3 link">
                    <b>Forside</b>
                </a>
                <!-- <a href="/services" class="px-3 link">
                    <b>Tjenester</b>
                </a> -->
                <a href="/articles" class="px-3 link">
                    <b>Artikler</b>
                </a>
                <a href="/contact" class="px-3 link">
                    <b>Ta kontakt</b>
                </a>
                <div v-if="auth > 0" class="flex">
                    <div>
                        |
                    </div> 
                    <a href="/contact/inbox" class="px-3 link">
                        <b>Innboks</b>
                    </a>
                </div>
            </div>
        </div>
        <div v-if="auth > 0" class="hidden md:inline">
            <form @submit.prevent="submitLogout()">
                <button type="submit" class="hover:text-main">
                    <b>Logg ut</b>
                </button>
            </form>
        </div>

        <!-- Mobile -->
        <div class="md:hidden">
            <button @click="toggleDropdown()" @click-outside="toggleDropdown()" class="flex items-center justify-between w-full py-2 text-gray-900hover:text-blue-700">
                <div v-if="!showDropdown" class="w-5 h-5">
                    <img src="/icons/hamburger.png" alt="">
                </div>
                <div v-else class="w-5 h-5">
                    <img src="/icons/close.png" alt="">
                </div>
            </button>
            <!-- Dropdown menu -->
            <div v-if="showDropdown" style="top: 60px; left: 0" class="w-full absolute z-10 font-normal bg-secondary border-b border-gray-300" >
                <ul class="py-2 text-sm">
                    <li>
                        <a href="/" class="block px-6 py-2 hover:bg-main hover:text-white">
                            <b>Forside</b>
                        </a>
                    </li>
                    <li>
                        <a href="/articles" class="block px-6 py-2 hover:bg-main hover:text-white">
                            <b>Artikler</b>
                        </a>
                    </li>
                    <li>
                        <a href="/contact" class="block px-6 py-2 hover:bg-main hover:text-white">
                            <b>Kontakt oss</b>
                        </a>
                    </li>
                    <!-- <li>
                        <a href="/services" class="block px-4 py-2 hover:bg-gray-100">Tjenester</a>
                    </li> -->
                    <li v-if="auth > 0">
                        <form @submit.prevent="submitLogout()">
                            <button type="submit" class="block px-6 py-2 hover:bg-main hover:text-white">
                                <b>Logg ut</b>
                            </button>
                        </form>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        auth: {
            type: Number
        }
    },

    data() {
        return {
            showDropdown: false
        }
    },

    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        },

        submitLogout() {
            axios
                .post('/logout')
                .then(response => {
                    location.href = '/'
                })
        }
    }
}
</script>