<template>
    <div class="flex flex-col md:flex-row py-8 px-24 mt-2 md:mt-6">
        <div class="md:w-1/3 flex flex-col justify-center items-center md:items-start text-left text-center md:text-start">
            <b class="">Innhold</b>
            <a href="/" class="link">Forside</a>
            <!-- <a href="/services" class="link">Tjenester</a> -->
            <a href="/articles" class="link">Artikler</a>
            <a href="/contact" class="link">Ta kontakt</a>
        </div>
        <div class="md:w-1/3 flex justify-center items-center text-center my-6 md:my-0">
            Copyright ⓒ Berget Applications
        </div>
        <div class="md:w-1/3 flex flex-col justify-center items-center md:items-end text-center md:text-end">
            <a href="https://www.flaticon.com" title="app icons" style="font-size: 10px">
                Icons from Flaticon
            </a>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>
