<template>
    <div class="w-full w-full sm:w-1/2 xl:w-1/4 px-4 mb-16">
        <a :href="`/articles/${article.id}`">
            <div class="bg-gray-200 flex justify-center" style="height: 35vh">
                <img v-if="article.media" :src="article.media" alt="" class="object-cover" style="height: 100%; width: 100%">
            </div>
        </a>
        <div class="flex flex-col justify-between" style="height: 35vh">
            <div>
                <h2 class="mt-6 text-xl">
                    {{ article.title }}
                </h2>
                <p>
                    {{ article.content_summary }}
                </p>
            </div>

            <div>
                <a :href="`/articles/${article.id}`" class="btn py-2 px-3">
                    Les mer
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true,
        }
    }
}
</script>
