<template>
    <div class="relative flex items-center justify-center">
        <img src="/icons/phone-app.png" alt="" class="absolute animate__animated" :class="iconPhoneClass">
        <img src="/icons/web-app.png" alt="" class="absolute animate__animated" :class="iconWebClass">
    </div>
</template>

<script>

export default {

    data() {
        return {
            iconPhoneClass: this.iconWebClass = {
                active: false,
                'hidden': true
            },
            iconWebClass: this.iconWebClass = {
                active: false,
                'hidden': true
            }
        }
    },

    mounted() {
        this.activateAnimation()
    },

    methods: {
        activateAnimation() {    
            setTimeout(() => {
                this.iconWebClass = {
                    active: true,
                    'animate__fadeInLeft': true
                }
                setTimeout(() => {
                    this.iconWebClass = {
                        active: true,
                        'animate__fadeOutRight': true
                    }
                    setTimeout(() => {
                        this.iconPhoneClass = {
                            active: true,
                            'animate__fadeInLeft': true
                        }

                        setTimeout(() => {
                            this.iconPhoneClass = {
                                active: true,
                                'animate__fadeOutRight': true
                            }
                            this.activateAnimation()
                        }, 3000)
                    }, 700)
                }, 3000)
            }, 700)        
        }
    }
}
</script>