<template>
    <form @submit.prevent="submit()" class="w-full md:w-1/2">
        <h1 class="mb-8 text-2xl">
            {{ this.article ? 'Oppdater blogg' : 'Opprett blogg' }}
        </h1>
        <div v-if="successfullySent" class="bg-green-100 border border-green-500 rounded px-2 py-2 mb-8">
            Melding sendt
        </div>
        <div v-if="errorMessage" class="bg-red-100 border border-red-500 rounded px-2 py-2 mb-8">
            {{ errorMessage }}
        </div>
        <div class="flex flex-col">
            <label for="title">Tittel</label>
            <input id="title" v-model="form.title" type="text" class="input" maxlength="200">
        </div>
        <div v-if="errors?.title && errors.title.length > 0" class="text-red-600">
            {{ errors.title[0] }}
        </div>

        <div class="flex flex-col mt-4">
            <label for="content_summary">Oppsummering</label>
            <textarea id="content_summary" v-model="form.content_summary" rows="5" class="input" maxlength="200"></textarea>
        </div>
        <div v-if="errors?.content_summary && errors.content_summary.length > 0" class="text-red-600">
            {{ errors.content_summary[0] }}
        </div>

        <div class="flex flex-col mt-4">
            <label for="content">Innhold (HTML)</label>
            <textarea id="content" v-model="form.content" rows="5" class="input" maxlength="10000"></textarea>
        </div>
        <div v-if="errors?.content && errors.content.length > 0" class="text-red-600">
            {{ errors.content[0] }}
        </div>


        <div v-if="article?.media" class="h-32 w-32 mt-4 bg-gray-600">
            <img :src="article.media" alt="" class="object-contain" style="height: 100%; width: 100%">
        </div>
        <div class="flex flex-col mt-4">
            <label for="file">Last opp bilde</label>
            <input id="fileToUpload" type="file" @change="uploadFile" ref="fileUpload">
        </div>
        <div v-if="errors?.content && errors.content.length > 0" class="text-red-600">
            {{ errors.content[0] }}
        </div>

        <div class="flex justify-end mt-4">
            <button type="submit" class="btn py-3 px-8" :disabled="isLoading">
                {{ this.article ? 'Oppdater' : 'Opprett' }}
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: false,
            default: () => null
        }
    },

    mounted() {
        if (this.article) {
            this.form = this.article
        }
    },

    data() {
        return {
            form: {
                title: '',
                file: null,
                content: '',
                content_summary: '',
            },
            errors: null,
            errorMessage: '',
            isLoading: false,
            successfullySent: false,
        }
    },

    methods: {
        submit() {
            this.isLoading = true;
            this.successfullySent = false;

            var formData = new FormData();
            if (this.form.id) formData.append("id", this.form.id);
            if (this.form.file) formData.append("file", this.form.file);
            if (this.form.title) formData.append("title", this.form.title);
            if (this.form.content) formData.append("content", this.form.content);
            if (this.form.content_summary) formData.append("content_summary", this.form.content_summary);

            axios
                .post("/articles", formData)
                .then(response => {
                    this.successfullySent = true
                    window.location.href = '/articles/' +  response.data.id
                })
                .catch(error => {
                    this.errorMessage = error.response.data.message
                    this.errors = error.response.data.errors
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },

        uploadFile() {
            this.form.file = this.$refs.fileUpload.files[0]
        },

        emptyArticle() {
            return {

            }
        }
    }
}
</script>
