var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-6 md:px-24 flex items-center justify-between sticky top-0 bg-tertiary z-50",staticStyle:{"height":"60px"}},[_c('div',{staticClass:"flex items-center"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"hidden md:flex"},[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),(_vm.auth > 0)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-quinary"},[_vm._v("\n                    |\n                ")]),_vm._v(" "),_vm._m(4)]):_vm._e()])]),_vm._v(" "),(_vm.auth > 0)?_c('div',{staticClass:"hidden md:inline"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitLogout()}}},[_vm._m(5)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"md:hidden"},[_c('button',{staticClass:"flex items-center justify-between w-full py-2",on:{"click":function($event){return _vm.toggleDropdown()},"click-outside":function($event){return _vm.toggleDropdown()}}},[(!_vm.showDropdown)?_c('div',{staticClass:"w-5 h-5"},[_c('img',{attrs:{"src":"/icons/hamburger.png","alt":""}})]):_c('div',{staticClass:"w-5 h-5"},[_c('img',{attrs:{"src":"/icons/close-blue.png","alt":""}})])]),_vm._v(" "),(_vm.showDropdown)?_c('div',{staticClass:"w-full absolute z-10 font-normal bg-tertiary",staticStyle:{"top":"60px","left":"0"}},[_c('ul',{staticClass:"py-2 text-sm"},[_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),(_vm.auth > 0)?_c('li',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitLogout()}}},[_vm._m(9)])]):_vm._e()])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"h-8 md:h-10",attrs:{"src":"/images/logo-light-blue.png","alt":"Berget Applications - Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"ml-8 px-3 link",attrs:{"href":"/"}},[_c('b',[_vm._v("Forside")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"px-3 link",attrs:{"href":"/articles"}},[_c('b',[_vm._v("Artikler")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"px-3 link",attrs:{"href":"/contact"}},[_c('b',[_vm._v("Ta kontakt")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"px-3 link",attrs:{"href":"/contact/inbox"}},[_c('b',[_vm._v("Innboks")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"text-quinary hover:text-main",attrs:{"type":"submit"}},[_c('b',[_vm._v("Logg ut")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"block px-6 py-2 text-quinary hover:bg-main hover:text-white",attrs:{"href":"/"}},[_c('b',[_vm._v("Forside")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"block px-6 py-2 text-quinary hover:bg-main hover:text-white",attrs:{"href":"/articles"}},[_c('b',[_vm._v("Artikler")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"block px-6 py-2 text-quinary hover:bg-main hover:text-white",attrs:{"href":"/contact"}},[_c('b',[_vm._v("Kontakt oss")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"block px-6 py-2 text-quinary hover:bg-main hover:text-white",attrs:{"type":"submit"}},[_c('b',[_vm._v("Logg ut")])])
}]

export { render, staticRenderFns }