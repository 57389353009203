<template>
    <form @submit.prevent="submit()" class="w-full">
        <div v-if="successfullySent" class="bg-green-100 border border-green-500 rounded px-2 py-2 mb-8">
            Melding sendt
        </div>
        <div v-if="errorMessage" class="bg-red-100 border border-red-500 rounded px-2 py-2 mb-8">
            {{ errorMessage }}
        </div>
        <div class="flex flex-col">
            <label for="name" class="text-gray-600 mb-3">Navn</label>
            <input id="name" v-model="form.name" type="text" class="input" maxlength="200">
        </div>
        <div v-if="errors?.name && errors.name.length > 0" class="text-red-600">
            {{ errors.name[0] }}
        </div>

        <div class="flex flex-col mt-4">
            <label for="email" class="text-gray-600 mb-3">E-post</label>
            <input id="email" v-model="form.email" type="email" class="input" maxlength="200">
        </div>
        <div v-if="errors?.email && errors.email.length > 0" class="text-red-600">
            {{ errors.email[0] }}
        </div>

        <div class="flex flex-col mt-4">
            <label for="message" class="text-gray-600 mb-3">Melding</label>
            <textarea id="message" v-model="form.message" rows="5" class="input" maxlength="2000"></textarea>
        </div>
        <div v-if="errors?.message && errors.message.length > 0" class="text-red-600">
            {{ errors.message[0] }}
        </div>

        <div class="flex justify-end mt-4">
            <button type="submit" class="btn py-3 px-8" :disabled="isLoading">
                Send
            </button>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    message: ''
                },
                errors: null,
                errorMessage: '',
                isLoading: false,
                successfullySent: false,
            }
        },

        methods: {
            submit() {
                this.isLoading = true;
                this.successfullySent = false;
                axios
                    .post("/contact", this.form)
                    .then(response => {
                        this.successfullySent = true
                        this.resetPage()
                    })
                    .catch(error => {
                        this.errorMessage = error.response.data.message
                        this.errors = error.response.data.errors
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            },

            resetPage() {
                this.errors = null;
                this.form.name = '';
                this.form.email = '';
                this.errorMessage = '';
                this.form.message = '';
            }
        }
    }
</script>
